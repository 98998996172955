import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as firebase from "firebase/app";
import activationCodeAX1800 from "assets/images/activation-code-AX1800.png";
import activationCodeAX3000 from "assets/images/activation-code-AX3000.png";

import { Dispatch } from "../../store";
import PageLayout from "../../layouts/PageLayout";
import OnboardingSteps from "./components/OnboardingSteps";
import { ReactComponent as IconProgress } from "../../assets/images/svg-icons/progress.svg";
import { useHistory } from "react-router";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { tryCatch } from "utils";
import { FoundAdapter } from "model";
import api from "api";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import InformationMessage from "components/InformationMessage";
import Input from "components/Input";
import Button from "components/Button";

const ActivateContainer: React.FC<{}> = () => {
  const [findingRouter, setFindingRouter] = useState<boolean>(false);
  const [offline, setOffline] = useState<boolean>(false);
  const [manualReboot, setManualReboot] = useState<boolean>(false);
  const [firmwareUpdate, setFirmwareUpdate] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");

  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const { t } = useTranslation("onboarding");

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "activate_router" });
    findAndClaimAdapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function claimAdapter(adapter: FoundAdapter) {
    firebase.analytics().logEvent("claim_adapter", { type: "automatic" });
    FirebaseAnalytics.logEvent("claim_adapter", { type: "automatic" });
    api.adapter
      .claimAdapter(adapter.id)
      .then(async (adapter) => {
        console.log("Adapter claimed: ", adapter);
        dispatch.notifications.clearNotifications();
        await dispatch.adapter.setAdapter(adapter.data);
        dispatch.adapter.setOnboardingStage("after-claim");
        window.sessionStorage.setItem("reloadAfterFind", "notReloaded");
        history.push("/onboarding/router-found");
      })
      .catch((error) => {
        console.log("Error in claimAdapter: " + error);
        dispatch.ui.setToast({
          type: "error",
          description: t("generic:errors.claimFailedMessage"),
        });
        history.push("/onboarding/get-connected");
      });
  }

  async function findAndClaimAdapter() {
    dispatch.ui.setToast(undefined);
    setFindingRouter(true);
    setOffline(false);
    setManualReboot(false);
    setFirmwareUpdate(false);

    const response = await tryCatch(
      api.adapter.findUnclaimedAdapters(code || undefined)
    );
    if (response.error) {
      console.log("Error in findUnclaimedAdapters: " + response.error);
    } else {
      const adapters = response.result.data;

      if (adapters.length === 0 && code) {
        //we could not find an adapter by IP or code, fail out
        history.push("/onboarding/router-not-found");
        return;
      }

      //check for adapters that are offline (and possibly updating)
      let foundOffline = false;
      adapters.forEach((adapter) => {
        if (!adapter.online) {
          foundOffline = true;
          setOffline(true);

          if (adapter.fw_status) {
            if (adapter.fw_status.manual_reboot) {
              //presumed legacy code, should not be true with current adapters
              setManualReboot(true);
            }

            if (adapter.fw_status.fw.os) {
              if (adapter.fw_status.fw.os.update) {
                setFirmwareUpdate(true);
              }
              if (adapter.fw_status.fw.os.pct > -1) {
                setFirmwareUpdate(true);
              }
            }

            if (adapter.fw_status.fw.recovery) {
              if (adapter.fw_status.fw.recovery.update) {
                setFirmwareUpdate(true);
              }
              if (adapter.fw_status.fw.recovery.pct > -1) {
                setFirmwareUpdate(true);
              }
            }
          }
        }
      });

      if (!foundOffline) {
        if (adapters.length === 1) {
          //single adapter found by IP or code, claim it
          claimAdapter(adapters[0]);
        } else {
          //either 0 or 2+ adapters found by IP, ask for code
          setFindingRouter(false);
          dispatch.ui.setToast({
            type: "error",
            description: t("generic:errors.activationCodeMessage"),
          });
        }
      }
    }
  }

  return (
    <PageLayout testId="activate-screen">
      <OnboardingSteps stage="activate" />
      <Title title={t("activate.title")} className="mb-4" />
      {offline ? (
        <>
          {manualReboot && (
            <>
              <div data-testid="router-needsreboot">
                <InformationMessage text={t("activate.needsRebootMessage")} />
              </div>
            </>
          )}
          {firmwareUpdate && (
            <>
              <div data-testid="router-updating">
                <InformationMessage text={t("activate.updatingMessage")} />
              </div>
            </>
          )}
          {!manualReboot && !firmwareUpdate && (
            <>
              <div data-testid="router-offline">
                <InformationMessage text={t("activate.offlineMessage")} />
              </div>
            </>
          )}
          <Button
            className="mt-3"
            type="button"
            onClick={() => findAndClaimAdapter()}
            data-testid="retry"
          >
            Retry
          </Button>
        </>
      ) : (
        <>
          {findingRouter ? (
            <div data-testid="looking-for-router">
              <div className="updating-icon large-general large-top">
                <div className="inner-updating">
                  <IconProgress className="animated" id="icon-progess" />
                </div>
              </div>
              <InformationMessage text={t("activate.loadingMessage")} />
            </div>
          ) : (
            <div data-testid="activate-router">
              <InformationMessage
                text={t("activate.instructions")}
                className="mb-2"
              />
              <Input
                id="activate-input"
                type="text"
                label={t("activate.codeLabel")}
                placeholder={t("activate.codePlaceholder")}
                className="mb-2"
                inputProps={{
                  value: code,
                  onChange: (e: any) => setCode(e.target.value),
                  maxLength: 5,
                }}
              />
              <Button
                disabled={code.length !== 5}
                type="button"
                onClick={() => findAndClaimAdapter()}
                data-testid="next"
              >
                Next
              </Button>
              <img
                src={activationCodeAX3000}
                alt="activation code on AX3000"
                style={{ marginTop: 30 }}
              />
              <img
                src={activationCodeAX1800}
                alt="activation code on AX1800"
                style={{ marginTop: 30 }}
              />
            </div>
          )}
        </>
      )}
    </PageLayout>
  );
};

export default ActivateContainer;
